import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, { Fragment, useEffect, useState } from "react";
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Col from "react-bootstrap/Col";
import "../../App.scss";
import "../CentralShopScss/CommonCardInfo.scss";
import { CondolenceApi } from "../../api/CondelenceApi";
import { Spinner } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { BackButton } from "../BackButton/BackButton";
import { PAGES } from "../../Pages";
import { ProductCarousel } from "../ProductCarousel/ProductCarousel";
import { useComfortListCards } from "./useComfortListCards";
import { DateHelper } from "../../helper/DateHelper";
import { toast } from "react-toastify";
import { SuccessImage } from "../Shared/SuccessImage";

function ComfortListCardInfo() {
  const { t, i18n } = useTranslation();
  const [dossierData, setDossierData] = useState();
  const [loading, setLoading] = useState(false);
  const { name } = useParams();
  const navigate = useNavigate();
  const { comfortListCardsMap } = useComfortListCards(dossierData);
  const [searchParams] = useSearchParams();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchDossierByAlias(name)
      .then((res) => {
        setDossierData(res);
        setLoading(false);
      })
      .catch((_) =>
        toast.error(t("error.general"), { position: "top-center" })
      );
  }, [name, t]);

  useEffect(() => {
    if (searchParams.has("hash")) {
      const hash = searchParams.get("hash");
      searchParams.delete("hash");
      CondolenceApi.getMourningProductPaymentStatus(hash)
        .then((res) => {
          if (res.status === "paid") {
            setShowImage(true);
            toast.success(t("comfort_card.order_success"), {
              position: "top-center",
            });
          } else {
            toast.warn(t("comfort_card.order_error"), {
              position: "top-center",
            });
          }
        })
        .catch((_) =>
          toast.error(t("error.general"), { position: "top-center" })
        );
    }
  }, [searchParams, t]);

  async function fetchDossierByAlias(alias = String) {
    return CondolenceApi.getDossierByAlias(alias);
  }

  function cardClicked(item, cardImages) {
    navigate(generatePath(PAGES.ComfortListShop, { name }), {
      state: { cardType: item, cardImages },
    });
  }

  function getMourningProductDetailByProps(item, props) {
    let detail = dossierData.mourning_products?.find((d) => d.key === item)?.[
      props
    ];
    return detail;
  }

  return (
    <Container>
      <Row>
        <BackButton
          path={generatePath(PAGES.PersonDetail, { name: name })}
          textComponent={<Trans t={t} i18nKey={"common.back"} />}
        />
      </Row>

      {loading === true && (
        <Row className={"justify-content-center mb-4"}>
          <Spinner animation="border" />
        </Row>
      )}

      {!!dossierData && (
        <Fragment>
          <Row className="mx-2 justify-content-md-center mb-5">
            <Col md={8} className={"align-content-end"}>
              <h1 className="participation-card-header-title">
                <Trans t={t} i18nKey={"comfort_card.title"} />
              </h1>
            </Col>
          </Row>
          <Row className="mx-2 justify-content-md-center mb-2">
            <Col md={8} className={"align-content-end"}>
              <p className="participation-card-header-title">
                <Trans t={t} i18nKey={"comfort_card.info"} />
              </p>
            </Col>
          </Row>
          <Row className="mx-2 justify-content-md-center mb-4">
            <Col md={8} className="align-self-center mb-2 pb-2">
              <h1>{dossierData.deceased_full_name}</h1>
              <span className="m-1 h5">
                {DateHelper.formatDateWithLocale(
                  dossierData.deceased.BIRTHDATE,
                  "DD MMMM YYYY",
                  i18n.language
                )}
              </span>
              &nbsp;|&nbsp;
              <span className="m-1 h5">
                {DateHelper.formatDateWithLocale(
                  dossierData.date_of_passing,
                  "DD MMMM YYYY",
                  i18n.language
                )}
              </span>
            </Col>
          </Row>
          <hr />
          <Row>
            {[...comfortListCardsMap.keys()].map((item) => (
              <Col key={item} md={4} className={"my-3"}>
                <ProductCarousel
                  onClick={() => {
                    cardClicked(item, comfortListCardsMap.get(item));
                  }}
                  images={comfortListCardsMap.get(item)}
                  price={getMourningProductDetailByProps(item, "price")}
                  title={getMourningProductDetailByProps(item, "key")}
                />
              </Col>
            ))}
          </Row>
        </Fragment>
      )}
      <SuccessImage showImage={showImage} />
    </Container>
  );
}
export default ComfortListCardInfo;
