import React from "react";
import {Carousel} from "react-bootstrap";
import classnames from "classnames";
import './ProductCarousel.scss'

export function ProductCarousel({images, price, classname, onClick = undefined, title}) {

    return (
        <div className={classnames(classname, "card-carousel")}>
            <Carousel>
                {images.map((i, index) => (
                    <Carousel.Item style={{zIndex:1}} key={index}>
                        <img
                            className="d-block w-100"
                            src={i}
                            alt="Carousel"
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            {/*{!!title &&<p>{title}</p>}*/}
            {!!price && <p>€{price}</p>}
            {!!onClick && <div className={classnames("card-shop-overlay")} onClick={onClick}></div>}
        </div>
    )
}