import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import "./FinancialSupport.scss";
import * as formik from "formik";
import * as yup from "yup";
import { generatePath, useParams } from "react-router-dom";
import { CondolenceApi } from "../../api/CondelenceApi";
import { PAGES } from "../../Pages";
import { toast } from "react-toastify";
import { NumberHelper } from "../../helper/NumberHelper";
import { Col } from "react-bootstrap";

const FinancialSupportForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [executingCall, setExecutingCall] = useState(false);
  const [showError, setShowError] = useState(false);
  const [resultStep] = useState({
    name: "",
    amount: 50,
    message: "",
  });
  const { Formik } = formik;
  const schema = yup.object().shape({
    name: yup.string(),
    message: yup.string(),
    amount: yup.number().required(),
  });

  function getRedirectUrl() {
    return `${window.location.origin}${generatePath(PAGES.FinancialSupport, {
      name: params.name,
    })}`;
  }

  function submitHandlerStep(props) {
    setExecutingCall(true);
    CondolenceApi.addCrowdfunding(
      params.name,
      getRedirectUrl(),
      props.name,
      props.message,
      props.amount
    )
      .then((res) => {
        window.location.href = res.CHECKOUT_URL;
      })
      .catch((err) => {
        // If Error exists in data use it, otherwise show general error
        if (err.response.data && err.response.data.Error) {
          if (err.response.data.Error === "maximum_reached") {
            setShowError(true);
          }
          toast.error(
            t("error." + err.response.data.Error, {
              value:
                typeof err.response.data.Message === "number"
                  ? NumberHelper.format(err.response.data.Message, false)
                  : err.response.data.Message,
            }),
            {
              position: "top-center",
            }
          );
        } else {
          toast.error(t("error.general"), {
            position: "top-center",
          });
        }
      })
      .finally(() => {
        setExecutingCall(false);
      });
  }

  return (
    <>
      <Col sm={0} md={1}></Col>
      <Col sm={12} md={5}>
        <h3 className="mb-3 mt-3 text-end">
          <Trans t={t} i18nKey={"financial_support.form_title"} />
        </h3>
        <h4 className="mb-3 mt-3 text-end">
          <Trans t={t} i18nKey={"financial_support.description"} />
        </h4>
      </Col>
      <Col sm={12} md={5}>
        <div className={"bg-crowdfunding-form crowdfunding-form-wrapper"}>
          {showError ? (
            <h4 className="mb-3 mt-3 text-center">
              <Trans t={t} i18nKey={"error.maximum_reached"} />
            </h4>
          ) : (
            <Formik
              validationSchema={schema}
              initialValues={resultStep}
              onSubmit={submitHandlerStep}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form
                  className="crowdfunding-form bg-crowdfunding-form mx-4 px-4 border-0"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Form.Group
                    className="mb-3 mt-10 text-padding"
                    controlId="formBasicName"
                  >
                    <Form.Label>
                      <Trans t={t} i18nKey={"financial_support.name"} />
                    </Form.Label>
                    <br />
                    <Form.Control
                      as="input"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name && touched.name}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 mt-10 text-padding"
                    controlId="formBasicName"
                  >
                    <Form.Label>
                      <Trans t={t} i18nKey={"financial_support.amount"} />
                    </Form.Label>
                    <br />
                    <Form.Control
                      type="number"
                      name="amount"
                      min={25}
                      step={1}
                      value={values.amount}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Prevent setting values less than 1
                        if (value === "" || parseInt(value, 10) >= 1) {
                          handleChange(e); // Call your formik or validation handler
                        }
                      }}
                      isInvalid={!!errors.amount && touched.amount}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 mt-10 text-padding"
                    controlId="formBasicName"
                  >
                    <Form.Label>
                      <Trans t={t} i18nKey={"financial_support.message"} />
                    </Form.Label>
                    <br />
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={3}
                      value={values.message}
                      onChange={handleChange}
                      isInvalid={!!errors.message && touched.message}
                    />
                  </Form.Group>
                  <Button
                    className="mb-4 mt-3 btn-condoleren"
                    variant="secondary"
                    type="submit"
                    disabled={executingCall}
                  >
                    <Trans t={t} i18nKey={"common.complete_payment"} />
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Col>
      <Col sm={0} md={1}></Col>
    </>
  );
};
export default FinancialSupportForm;
