import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CondolenceApi } from "../../api/CondelenceApi";
import Row from "react-bootstrap/Row";
import { Spinner } from "react-bootstrap";
import "./FinancialSupport.scss";
import { useSearchParams } from "react-router-dom";
import { SuccessImage } from "../Shared/SuccessImage";

export const FinancialSupportResult = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const [status_message, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setLoading(true);
    CondolenceApi.getCrowdfundingStatus(hash)
      .then((res) => {
        if (res.status === "paid") {
          setStatusMessage("success");
        } else {
          setStatusMessage("failed");
        }
      })
      .catch((_) => toast.error(t("error.general"), { position: "top-center" }))
      .finally(() => setLoading(false));
  }, [hash, t]);

  useEffect(() => {
    if (status_message === "success") {
      setShowImage(true);

      const timer = setTimeout(() => {
        setShowImage(false);
      }, 15000); // 15 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [status_message]);

  return (
    <>
      {loading === true && (
        <Row className={"justify-content-center mb-4"}>
          <Spinner animation="border" />
        </Row>
      )}
      {loading === false && (
        <Row
          className={`justify-content-center bg-crowdfunding-${status_message}`}
        >
          <h3 className="mb-3 mt-3 text-center w-75">
            <Trans
              t={t}
              i18nKey={`financial_support.status.${status_message}`}
            />
          </h3>
        </Row>
      )}
      <SuccessImage showImage={showImage} />
    </>
  );
};
