import React from "react";
import "./SuccessImage.scss";
import Success from "../../assets/images/CMS_bedankt.png";

export const SuccessImage = ({ showImage }) => {
  return (
    showImage && (
      <div className="status-image-container">
        <img src={Success} alt="Success" className="status-image" />
      </div>
    )
  );
};
