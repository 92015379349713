 import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import * as formik from "formik";
import {Trans, useTranslation} from "react-i18next";
import './CondolencesForm.scss'
import {CondolenceApi} from "../../api/CondelenceApi";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {useState} from "react";

export default function CondolencesForm({dossierNumber, isCondolencePublic = true}) {
    const {t} = useTranslation();
    const {Formik} = formik;
    const [executingCall, setExecutingCall] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string(),
        phone: yup.string(),
        address: yup.string(),
        message: yup.string().required(),
        public: yup.bool(),
        confirmation: yup.bool().oneOf([true], 'Field must be checked').required()
    });

    const initialValue = {
        name: "",
        email: "",
        phone: "",
        address: "",
        message: "",
        isPublic: false,
        confirmation: false
    }

    function submitHandler({name, email, phone, address, message, isPublic}, {resetForm}) {
        setExecutingCall(true);
        CondolenceApi.createCondolence(dossierNumber, name, email, phone, address, message, isPublic)
            .then(_ => {
                setExecutingCall(false);
                toast.success(t('condolence.creation_success'), {position: "top-center"});
                resetForm();
            })
            .catch(_ => {
                toast.error(t('error.general'), {position: "top-center"});
                setExecutingCall(false);
            });
    }

    return (
        <Formik validationSchema={schema} initialValues={initialValue} onSubmit={submitHandler}>
            {
                ({handleSubmit, handleChange, values, touched, errors}) => (
                    <Form className='bg-condolence-form pt-4 px-4 border-0' noValidate onSubmit={handleSubmit}>
                        <h3 className='mb-5'><Trans t={t} i18nKey={'common.condolences'}/></h3>
                        <Form.Group className="mb-4" controlId="formBasicName">
                            {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.name'}/></Form.Label>*/}
                            <Form.Control type="text"
                                          placeholder={t("common.name")}
                                          name="name"
                                          value={values.name}
                                          onChange={handleChange}
                                          isInvalid={!!errors.name && touched.name}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.email'}/></Form.Label>*/}
                            <Form.Control type="email"
                                          placeholder={t("common.email")}
                                          name="email"
                                          value={values.email}
                                          onChange={handleChange}
                                          isInvalid={!!errors.email && touched.email}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicTelephone">
                            {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.telephone'}/></Form.Label>*/}
                            <Form.Control type="text"
                                          placeholder={t("common.telephone")}
                                          name="phone"
                                          value={values.phone}
                                          onChange={handleChange}
                                          isInvalid={!!errors.phone && touched.phone}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicAddress">
                            {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.address'}/></Form.Label>*/}
                            <Form.Control type="text"
                                          placeholder={t("common.address")}
                                          name="address"
                                          value={values.address}
                                          onChange={handleChange}
                                          isInvalid={!!errors.address && touched.address}
                            />
                        </Form.Group>
                        <Form.Group className="mb-5" controlId="formBasic">
                            {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.message'}/></Form.Label>*/}
                            <Form.Control rows={4} as="textarea"
                                          placeholder={t("common.message")}
                                          name="message"
                                          value={values.message}
                                          onChange={handleChange}
                                          isInvalid={!!errors.message && touched.message}
                            />
                        </Form.Group>
                        {
                            isCondolencePublic &&
                            <Form.Group className={"mb-4"} controlId="formBasicCheckboxPubilc">
                                <Form.Check type="checkbox"
                                            label={<Trans t={t} i18nKey={'condolence.is_public'}/>}
                                            name="isPublic"
                                            value={values.isPublic}
                                            onChange={handleChange}
                                            isInvalid={!!errors.isPublic && touched.isPublic}
                                />
                            </Form.Group>

                        }
                        <Form.Group className={"mb-4"} controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                        label={<Trans t={t} i18nKey={'condolence.privacy_consent'}/>}
                                        name="confirmation"
                                        value={values.confirmation}
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirmation && touched.confirmation}
                            />
                        </Form.Group>
                        <Button disabled={executingCall} className='mb-4 mt-3 btn-condoleren' variant="secondary"
                                type="submit">
                            <Trans t={t} i18nKey={'common.condolences'}/>
                        </Button>
                        <Link style={{
                            color: "black"
                        }} to={"https://www.sereni.be/artikel/help-ik-weet-niet-wat-zeggen-bij-een-begrafenis/"}>
                            <p>
                                <Trans t={t} i18nKey={'condolence.no_correct_words'}/> <br/>
                                <Trans t={t} i18nKey={'condolence.tips'}/>
                            </p>
                        </Link>
                        <p style={{marginBottom: '5rem'}}>{isCondolencePublic ? '' : <Trans t={t} i18nKey={'condolence.other_text'}/>}</p>
                    </Form>
                )
            }
        </Formik>
    );
}
