import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {generatePath, useParams} from "react-router-dom";
import * as formik from "formik";
import * as yup from "yup";
import {PAGES} from "../../../Pages";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {CondolenceApi} from "../../../api/CondelenceApi";
import "../../CentralShopScss/CommonCardForm.scss";
import {toast} from "react-toastify";
import {InvoicingDetailsForm} from "../../Shared/InvoicingDetailsForm";

const ComfortListCardForm = ({selectedProduct}) => {
    const {t, i18n} = useTranslation();
    const params = useParams();
    const [executingCall, setExecutingCall] = useState(false);
    const [step, setStep] = useState(1);
    const [resultStep1, setResultStep1] = useState({
        message: ""
    });
    const [resultStep2, setResultStep2] = useState({
        name: "",
        phone: "",
        email: "",
        invoice: false,
        company: "",
        vatNumber: "",
        street: "",
        postalCode: "",
        city: "",
    });
    const {Formik} = formik;

    const schema = yup.object().shape({
        message: yup.string()
    });

    const schema2 = yup.object().shape({
        name: yup.string().required(),
        phone: yup.string().required(),
        invoice: yup.bool().required(),
        email: yup.string().required(),
        company: yup.string(),
        vatNumber: yup.string(),
        street: yup.string(),
        postalCode: yup.string(),
        city: yup.string()
    });

    function submitHandlerStep2(props) {
        setExecutingCall(true);
        CondolenceApi.orderMourningProduct(
            params.name,
            getRedirectUrl(),
            selectedProduct,
            props.name,
            props.phone,
            props.invoice,
            props.email,
            props.vatNumber,
            props.company,
            props.street,
            props.postalCode,
            props.city,
            resultStep1.message,
            i18n.language.toLowerCase() === "fr" ? "fr_FR" : "nl_NL"
        )
            .then((res) => {
                window.location.href = res.CHECKOUT_URL;
                setExecutingCall(false);
            })
            .catch((_) => {
                toast.error(t("error.invalid_customer"), {position: "top-center"});
                setExecutingCall(false);
            });
    }

    function stepBack(props) {
        setResultStep2(props);
        setStep(1);
    }

    function getRedirectUrl() {
        return `${window.location.origin}${generatePath(PAGES.ComfortList, {
            name: params.name,
        })}`;
    }

    function submitHandlerStep1(props) {
        setResultStep1(props);
        setStep(2);
    }

    function renderStep1() {
        return (
            <Formik validationSchema={schema}
                    initialValues={schema}
                    onSubmit={submitHandlerStep1}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                  }) => (
                    <Form
                        className="flower-form bg-flower-form pt-4 mx-4 px-4 border-0"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Form.Group
                            className="mb-3 mt-10 text-padding"
                            controlId="formBasicName"
                        >
                            <Form.Label>
                                <Trans t={t} i18nKey={"comfort_card.message"}/>
                            </Form.Label>
                            <br/>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                isInvalid={!!errors.message && touched.message}
                            />
                        </Form.Group>
                        <Button
                            className="mb-4 mt-3 btn-condoleren"
                            variant="secondary"
                            type="submit"
                        >
                            <Trans t={t} i18nKey={"common.complete_payment"}/>
                        </Button>
                    </Form>
                )}
            </Formik>);
    }

    return (
        <div
            className={"bg-participation-card-form participation-card-form-wrapper"}
        >
            {step === 1 && renderStep1()}
            {step === 2 && (
                <InvoicingDetailsForm
                    schema={schema2}
                    resultStep={resultStep2}
                    submitHandlerStep={submitHandlerStep2}
                    stepBack={stepBack}
                    executingCall={executingCall}
                />
            )}
        </div>
    );
};
export default ComfortListCardForm;
