import * as formik from "formik";
import Form from "react-bootstrap/Form";
import { Trans, useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
// import "./InvoicingDetailsForm.scss";
import React, { useEffect, useState } from "react";
import Arrow from "../../assets/images/pijlNav.svg";

export function InvoicingDetailsForm({
  schema,
  resultStep,
  submitHandlerStep,
  stepBack,
  executingCall,
}) {
  const { t } = useTranslation();
  const [vatValid, setVatValid] = useState(null);
  const { Formik } = formik;

useEffect(() => {
    if (resultStep.invoice) {
      handleVATCheck(resultStep.vatNumber);
    }
}, [resultStep.invoice, resultStep.vatNumber]);

  const handleVATCheck = async (rawVatNumber) => {
    if (rawVatNumber === undefined || rawVatNumber === null) {
      setVatValid(true);
      return;
    }

    let formattedVat = rawVatNumber.replaceAll(/\s|-|\.|,/g, "");

    if (formattedVat === "") {
      setVatValid(true);
      return;
    }

    /**
     * 1 Check if there is a country code in the first 2 character
     *  if not, show error
     * 2 Check if the country code is in the set [FR, NL, DE, BE, PL]
     *  if not, show error
     * 3 check if the vat code is formatted the same as the following templates:
     * FR: FRXX123456789
     * NL: NL123456789B01
     * DE: DE123456789
     * BE: BE0123456789
     * PL: PL1234567890
     */
    if (formattedVat.length < 2) {
      setVatValid(false);
    } else {
      let country = formattedVat.substring(0, 2);
      let vatNumber = formattedVat.substring(2);
      if (
        country === "BE" ||
        country === "NL" ||
        country === "DE" ||
        country === "FR" ||
        country === "PL"
      ) {
        if (country === "BE") {
          setVatValid(/^\d{10}$/.test(vatNumber));
        } else if (country === "NL") {
          setVatValid(/^(NL)?[0-9]{9}B[0-9]{2}$/.test(vatNumber));
        } else if (country === "DE") {
          setVatValid(/^\d{9}$/.test(vatNumber));
        } else if (country === "FR") {
          setVatValid(/^(FR)?[0-9A-Z]{2}[0-9]{9}$/.test(vatNumber));
        } else if (country === "PL") {
          setVatValid(/^\d{10}$/.test(vatNumber));
        }
      } else {
        setVatValid(false);
      }
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={resultStep}
      onSubmit={submitHandlerStep}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form
          className="flower-form bg-flower-form pt-4 mx-4 px-4 border-0"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={"form-back-button"} onClick={() => stepBack(values)}>
            <img src={Arrow} className={"back-arrow"} alt={"Arrow"} />
            <Trans t={t} i18nKey={"common.back"} />
          </div>
          <Form.Group className="mb-4">
            <Form.Label>
              <Trans t={t} i18nKey={"common.name"} />
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              isInvalid={!!errors.name && touched.name}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              <Trans t={t} i18nKey={"common.phone"} />
            </Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              isInvalid={!!errors.phone && touched.phone}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              <Trans t={t} i18nKey={"common.email"} />
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email && touched.email}
            />
          </Form.Group>
          <Form.Group className={"mb-4"} controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label={<Trans t={t} i18nKey={"common.invoice_requested"} />}
              name="invoice"
              value={values.invoice}
              onChange={(e) => {
                setFieldValue("invoice", e.target.checked);
                setVatValid(!e.target.checked || values.vatNumber === "");
              }}
              isInvalid={!!errors.invoice && touched.invoice}
            />
          </Form.Group>
          {values.invoice && (
            <>
              <Form.Group className="mb-4">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.company"} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  isInvalid={!!errors.company && touched.company}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.vatNumber"} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="vatNumber"
                  value={values.vatNumber}
                  onChange={handleChange}
                  onBlur={() => handleVATCheck(values.vatNumber)}
                  isInvalid={!!errors.vatNumber && touched.vatNumber}
                />
                {!vatValid && (
                  <div className="text-danger">{t("error.vat_number")}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.street"} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="street"
                  value={values.street}
                  onChange={handleChange}
                  isInvalid={!!errors.street && touched.street}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.postalCode"} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  isInvalid={!!errors.postalCode && touched.postalCode}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.city"} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  isInvalid={!!errors.city && touched.city}
                />
              </Form.Group>
            </>
          )}
          <Button
            disabled={executingCall || vatValid === false}
            className="mb-4 mt-3 btn-condoleren"
            variant="secondary"
            type="submit"
          >
            <Trans t={t} i18nKey={"common.pay"} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}
