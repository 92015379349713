import React, {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {BackButton} from "../../BackButton/BackButton";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {PAGES} from "../../../Pages";
import {Trans, useTranslation} from "react-i18next";
import {Spinner} from "react-bootstrap";
import {CondolenceApi} from "../../../api/CondelenceApi";
import Col from "react-bootstrap/Col";
import PersonInfo from "../../PersonDetail/PersonInfo";
import {ProductCarousel} from "../../ProductCarousel/ProductCarousel";
import '../../CentralShopScss/CommonCardShop.scss'
import {useCards} from "../useCards";
import CustomCarousel from "../../Carousel/CustomCarousel";
import {ParticipationCardForm} from "./ParticipationCardForm";
import {DateHelper} from "../../../helper/DateHelper";
import {toast} from "react-toastify";
import { Show } from "../../Util/Show";

export function ParticipationCardShop() {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const {name} = useParams();
    const [loading, setLoading] = useState(false);
    const [dossierData, setDossierData] = useState();
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const {cardsMap} = useCards();

    useEffect(() => {
        if (!!name) {
            setLoading(true);
            CondolenceApi.getDossierByAlias(name)
                .then(res => {
                    if (res.show_mourning_card) {
                        setDossierData(res);
                        setLoading(false);
                    } else {
                        navigate(PAGES.PersonDetail.replace(":name", name))
                    }
                })
                .catch(_ => toast.error(t('error.general'),{position: "top-center"}))
        }
    }, [name, navigate, t]);

    useEffect(() => {
        if (!!location) {
            if (!!location?.state?.cardType) {
                if (!!location.state.cardImages) {
                    setCards(location.state.cardImages);
                } else {
                    navigate(-1);
                }
            } else {
                navigate(-1);
            }
        }
    }, [location, navigate]);

    function cardClicked(item, cardImages) {
        navigate(generatePath(PAGES.ParticipationCardShop, {name}), {state: {cardType: item, cardImages}})
    }

    return (
        <Fragment>
            <Container>
                <Row className={"mb-5"}>
                    <BackButton path={generatePath(PAGES.ParticipationCard, {name: name})}
                                textComponent={<Trans t={t} i18nKey={"common.overview"}/>}/>
                </Row>
                {loading === true && (
                    <Row className={"justify-content-center mb-4"}>
                        <Spinner animation="border"/>
                    </Row>
                )}
                {!!dossierData && (
                    <Fragment>
                        <Row className="justify-content-center mb-5">
                            <Col className="display-person-info">
                                <PersonInfo dossierData={dossierData}/>
                            </Col>
                            <Col sm={10} lg={5}>
                                {/*    <h2 className="align-middle">{flowerPrice.label}</h2>*/}
                                <h1 className="mobile-display-hide font-domain">{dossierData.deceased_first_name + " " + dossierData.deceased_last_name}</h1>
                                <Show>
                                    <Show.When isTrue={dossierData.dossier_number !== "38984"}>
                                        <span
                                            className="m-1 h5 mobile-display-hide">{DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language)}  &nbsp;|&nbsp;</span>
                                        <span
                                            className="m-1 h5 mobile-display-hide">{DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)}
                                        </span>
                                    </Show.When>
                                </Show>
                                <div className="mobile-display-hide">
                                    <ProductCarousel images={cards} classname={"my-3 shop-carousel"}/>
                                </div>
                            </Col>
                            <Col lg={4} className={"align-self-center"}>
                                <ParticipationCardForm selectedCard={location.state.cardType}/>
                            </Col>
                        </Row>
                        <hr/>
                        <Row className={"justify-content-center"}>
                            <CustomCarousel visibleItemsCount={3} isInfinite>
                                    {[...cardsMap.keys()].map(item => (
                                        <Col key={item} md={3}>
                                            <ProductCarousel classname={"mx-2"} onClick={() => {
                                                cardClicked(item, cardsMap.get(item))
                                            }}
                                                             images={cardsMap.get(item)}
                                                             price={"9.00"}/>
                                        </Col>
                                    ))}
                            </CustomCarousel>
                        </Row>

                        <hr/>
                        <Row className="justify-content-md-center mobile-display-hide">
                            <Col md={4} className={"my-4"}>
                                <h3><Trans t={t} i18nKey={'participation_card.a_perfect_delivery'}/></h3>
                                <p><Trans t={t} i18nKey={'participation_card.a_perfect_delivery_text'}/></p>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </Container>
        </Fragment>
    )
}
