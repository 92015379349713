import {useEffect, useState} from "react";

const CardFolder = require.context("../../assets/images/comfortListCards", true);

export const useComfortListCards = (dossierData) => {
    const [comfortListCardsMap, setComfortListCardsMap] = useState(new Map());


    useEffect(() => {
        if (!!dossierData && dossierData.show_mourning_products && !!dossierData.mourning_products && dossierData.mourning_products.length > 0) {
            const availableProducts = dossierData.mourning_products;
            const map = new Map();
            CardFolder.keys().map(image => ({
                type: image.split("/")[1],
                file: CardFolder(image)
            })).forEach(i => {
                if (!!availableProducts.find(a => a.key === i.type)) {
                    map.has(i.type) ? map.get(i.type).push(i.file) : map.set(i.type, [i.file])
                }
            } );
            setComfortListCardsMap(map)
        }
    }, [dossierData]);
    return {
        comfortListCardsMap,
        amount: [...comfortListCardsMap.keys()].length
    }
}