import React, {Fragment, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {CondolenceApi} from "../../api/CondelenceApi";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {BackButton} from "../BackButton/BackButton";
import {Spinner} from "react-bootstrap";
import PersonInfo from "../PersonDetail/PersonInfo";
import Col from "react-bootstrap/Col";
import {Farewell} from "../PersonDetail/Farewell";
import {Show} from "../Util/Show";
import {MourningVisits} from "../PersonDetail/MourningVisits";
import {PublicCondolences} from "../PersonDetail/PublicCondolences";
import {FuneralHome} from "../PersonDetail/FuneralHome";
import './FinancialSupport.scss'
import FinancialSupportForm from "./FinancialSupportForm";
import { useSearchParams } from "react-router-dom";
import { FinancialSupportResult } from "./FinancialSupportResult";

export const FinancialSupportPage = () => {
    const [dossierData, setDossierData] = useState();
    const [loading, setLoading] = useState(false);
    const {name} = useParams();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const hasHash = searchParams.has("hash");

    useEffect(() => {
        setLoading(true);
        fetchDossierByAlias(name).then((res) => {
            setDossierData(res);
            setLoading(false);
        })
            .catch(_ => toast.error(t('error.general'),{position: "top-center"}));
    }, [name, t]);

    async function fetchDossierByAlias(alias = String) {
        return CondolenceApi.getDossierByAlias(alias);
    }

    function getFuneralExecutorName() {
        if (!!dossierData.funeral_entrepreneur) {
            return dossierData.funeral_entrepreneur;
        }
    }

    function renderLiveStreamButton() {
        if (dossierData?.show_livestream === true) {
            return (
                <Link to={dossierData.livestream_page} target={"_blank"}>
                    <Button className={"livestream-button"}>
                        <Trans t={t} i18nKey={"common.livestream"}/>
                    </Button>
                </Link>
            );
        }
        return (<Fragment/>);
    }

    return (
        <Container>
            <Row>
                <BackButton path={`/${name}`} textComponent={<Trans t={t} i18nKey={"common.back"}/>}/>
            </Row>
            <Row>
                {dossierData?.show_snoeck_button === true &&
                    <BackButton path={"https://www.snoeck-izegem.be/rouwberichten.html"}
                                textComponent={<Trans t={t} i18nKey={"common.back_snoeck"}/>}/>}
            </Row>
            {loading === true && (
                <Row className={"justify-content-center mb-4 mb-4"}>
                    <Spinner animation="border"/>
                </Row>
            )}
            {!!dossierData &&
                <Fragment>
                    <Row className="mt-5 align-middle text-center text-md-start">
                        <PersonInfo dossierData={dossierData}/>
                        <Col md={3} className={"person-detail-info-col ms-4"}>
                            <Farewell className={"mb-5"} funeralData={dossierData.funeral}
                                      funeralLocationData={dossierData.funerallocation}/>
                            <Show>
                                <Show.When
                                    isTrue={dossierData.funeral.PUBLICCHARACTER === '1' || dossierData.funeral.PUBLICCHARACTER === true}>
                                    <MourningVisits className="mb-5" showFuneral={dossierData.show_funeral}
                                                    mourningVisit={dossierData.mourningvisit}
                                                    mourningVisitLocation={dossierData.mourningvisitlocation}/>
                                </Show.When>
                            </Show>
                            <PublicCondolences className="mb-5"
                                               condolences={dossierData.condoleances}
                                               canShowPublicCondolences={dossierData.funeral.ALLOWPUBLICCONDOLENCES === "1"}/>

                            <FuneralHome funeralHomeData={dossierData.funeralhome}
                                         funeralEntrepreneur={getFuneralExecutorName()}/>

                            {renderLiveStreamButton()}
                        </Col>
                    </Row>
                    <Row className={"justify-content-center mt-3"}>
                        {hasHash ? (<FinancialSupportResult/>) : (<FinancialSupportForm/>)}
                    </Row>
                </Fragment>}
        </Container>
    )
}